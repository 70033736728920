<template>
    <div>
        <div class="pa-2 pt-3 d-flex justify-space-between align-center">
            <div class=" d-flex font-small">

                <span class="pr-1">صفحة</span>
                <span class="pr-1">{{$global.state.filter.pageIndex}}</span>
                
                <v-divider class="ml-1 mr-2" vertical></v-divider>
                
                <span class="px-1">عرض</span>
                <span>{{items.length}}</span>
                <span class="px-1">عنصر</span>

                <v-divider class="mx-1" vertical></v-divider>

                <span class="pr-1">من أصل</span>
                <span class="pr-1">{{$global.state.filter.count}}</span>
            </div>
            <v-spacer></v-spacer>
            <v-pagination
                @input="getItems()"
                color="primary"
                v-model="$global.state.filter.pageIndex"
                :length="$global.state.length"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: ["items"],

    created() {
        this.$global.state.filter.pageIndex = 1;
    },
    methods: {
        getItems() {
            this.$eventBus.$emit(`refresh`);
        },
    },
};
</script>

<style lang="scss" scoped>
.space {
    display: inline;
    height: 15px;
    background: grey;
    width: 1px;
}
</style>
